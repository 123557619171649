import React, {useEffect, useState} from "react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar/NavBar";

import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../slices/authSlice";
import { registerUser } from "../slices/authSlice";

import { useNavigate } from "react-router-dom";

// install Swiper modules
SwiperCore.use([Navigation]);
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [userRegistration, setUserRegistration] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    if (auth._id) {
      navigate("/cart");
    }
  }, [auth._id, navigate]);

  const handleSubmit = () => {
    dispatch(loginUser(user));
  };

  const handleSubmit2 = () => {
    dispatch(registerUser(userRegistration));
  };

  return (
    <>
      <NavBar />

      <div className="breadcrumb__area pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-breadcrumb__content">
                <div className="tp-breadcrumb__list">
                  <span className="tp-breadcrumb__active">
                    <a href="index.html">Home</a>
                  </span>
                  <span className="dvdr">/</span>
                  <span>Sign in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="track-area pb-40">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-sm-12">
              <div className="tptrack__product mb-40">
                <div className="tptrack__content grey-bg">
                  <div className="tptrack__item d-flex mb-20">
                    <div className="tptrack__item-icon">
                      <i className="fa fa-unlock-alt"></i>
                    </div>
                    <div className="tptrack__item-content">
                      <h4 className="tptrack__item-title">Login Here</h4>
                      <p>
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account.
                      </p>
                    </div>
                  </div>
                  <div className="tptrack__id mb-10">
                    <form action="#">
                      <span>
                        <i className="fa fa-user"></i>
                      </span>
                      <input
                        type="email"
                        placeholder="Username / email address"
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                      />
                    </form>
                  </div>
                  <div className="tptrack__email mb-10">
                    <form action="#">
                      <span>
                        <i className="fa fa-key"></i>
                      </span>
                      <input 
                        type="password" 
                        placeholder="Password"
                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                         />
                    </form>
                  </div>
                  <div className="tpsign__remember d-flex align-items-center justify-content-between mb-15">
                    <div className="form-check">
                      {/* <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault2"
                      />
                      <label className="form-check-label" for="flexCheckDefault2">
                        Remember me
                      </label> */}
                    </div>
                    <div className="tpsign__pass">
                      <a href="#">Forget Password</a>
                    </div>
                  </div>
                  <div className="tptrack__btn">
                    <button onClick={()=>{handleSubmit()}} className="tptrack__submition active">
                      {auth.loginStatus === "pending" ? "Submitting..." : "Login"}<i className="fa fa-long-arrow-right"></i>
                    </button>
                      {auth.loginStatus === "rejected" ? <p className="validationText">{auth.loginError}</p> : null}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="tptrack__product mb-40">
                <div className="tptrack__content grey-bg">
                  <div className="tptrack__item d-flex mb-20">
                    <div className="tptrack__item-icon">
                      <i className="fa fa-lock"></i>
                    </div>
                    <div className="tptrack__item-content">
                      <h4 className="tptrack__item-title">Sign Up</h4>
                      <p>
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account.
                      </p>
                    </div>
                  </div>
                  <div className="tptrack__id mb-10">
                    <form action="#">
                      <span>
                        <i className="fa fa-user"></i>
                      </span>
                      <input type="text" placeholder="name" 
                        onChange={(e) => setUserRegistration({ ...userRegistration, name: e.target.value })}/>
                    </form>
                  </div>
                  <div className="tptrack__email mb-10">
                    <form action="#">
                      <span>
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input type="email" placeholder="Email address" 
                        onChange={(e) => setUserRegistration({ ...userRegistration, email: e.target.value })}/>
                    </form>
                  </div>
                  <div className="tptrack__email mb-10">
                    <form action="#">
                      <span>
                        <i className="fa fa-key"></i>
                      </span>
                      <input type="password" placeholder="Password" onChange={(e) => setUserRegistration({ ...userRegistration, password: e.target.value })} />
                    </form>
                  </div>
                  <div className="tpsign__account mb-15">
                    <br />
                  </div>
                  <div className="tptrack__btn">
                    <button className="tptrack__submition tpsign__reg" onClick={()=>{handleSubmit2()}}>
                    {auth.rigisterStatus === "pending" ? "Submitting..." : "Register Now"}<i className="fa fa-long-arrow-right"></i>
                    </button>
                    {auth.registerStatus === "rejected" ? (
                        <p  className="validationText">{auth.registerError}</p>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
