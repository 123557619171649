import React, { useState, useEffect,useRef } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, getTotals } from "../slices/cartSlice";
import axios from "axios";
import Config from "../../src/config/Config"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import {BounceLoader} from "react-spinners";

// install Swiper modules
SwiperCore.use([Navigation]);
const Home = () => {
  let abortController = new AbortController();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const categoryParams = searchParams.get('category');
  const filterRef = useRef(null);
  const filterDivRef = useRef(null);
  const { items: data, status } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [isSticky, setSticky] = useState(false);
  const [firstDivWidth, setFirstDivWidth] = useState(0);
  const [ allData, setAllData ] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [urlText, setUrlText] = useState(0);
  const urls = [
    "products",
    "products/sortByPriceAsc",
    "products/sortByPriceDesc",
    "products/sortByNewProduct",
    "products/sortByDiscountProduct"
  ];

  const urls2 = [
    "products/filterByCategory",
    "products/sortByPriceAscAndFilterByCategory",
    "products/sortByPriceDescAndFilterByCategory",
    "products/sortByNewProductAndFilterByCategory",
    "products/sortByDiscountAndFilterByCategory"
  ];

  const [ allCategory, setAllCategory ] = useState([]);
  const [category, setCategory] = useState(categoryParams);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  function calculateTotalSum(categories) {
    return categories.reduce((accumulator, currentCategory) => {
      return accumulator + parseInt(currentCategory.count);
    }, 0); 
  }

  function getAllData(){
    setIsLoading(true)
      return axios.get(Config.BACKEND_URL + `/${urls[urlText]}?page=${pageNo}&pageSize=20`)
        .then((res) => {
          const data = res.data;
          setAllData(data);
    setIsLoading(false)

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
    setIsLoading(false)

        })
    
  }

  function getAllData2(mapId){  
    setIsLoading(true)

    return axios.get(Config.BACKEND_URL + `/${urls[mapId]}?page=${pageNo}&pageSize=20`)
      .then((res) => {
        const data = res.data;
        setAllData(data);
    setIsLoading(false)

      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
    setIsLoading(false)

      })
  
  }

  function getSearchData(value,signal){
    return fetch(`${Config.BACKEND_URL}/products/search?searchTerm=${value}&page=${pageNo}&pageSize=20`, {
      method: 'GET', // or 'POST'
      signal: signal, // Pass the signal to your fetch request
      // Other fetch options like headers, body, etc.
    })
    .then(response => response.json())
    .then(data => {
      // Handle your data here
      setAllData(data);
    })
    .catch(error => {
        // Handle other errors
        console.log('Fetch error:', error);
    });
  }

  function getFilterCategoryData(value){
    setIsLoading(true)

    const categoriesValue = encodeURIComponent(value);

    return axios.get(Config.BACKEND_URL + `/${urls2[urlText]}?Categories=${categoriesValue}&page=${pageNo}&pageSize=20`)
    .then((res) => {
      const data = res.data;
      setAllData(data);
    setIsLoading(false)

    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    setIsLoading(false)

    })
  }

  useEffect(() => {
    setIsLoading(true)

    // Check if the refs are defined
    if (filterRef.current && filterDivRef.current) {
      // Get the width of the filterDiv
      const filterDivWidth = filterDivRef.current.offsetWidth;

      // Set the width of the filter element based on the filterDiv width
      filterRef.current.style.width = `${filterDivWidth}px`;
    }

    window.scrollTo(0, 0);

    if(category){
      if(category === "All"){
        getAllData();
      }else{
        getFilterCategoryData(category)
      }
    }else{
      getAllData()
    }

    axios.get(Config.BACKEND_URL + `/products/counts`)
      .then((res) => {
      if(res?.status === 200){

        const payload =  { "categories": 'All', "count": calculateTotalSum(res?.data) }
        setAllCategory([payload, ...res?.data]);
    setIsLoading(false)

      }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
    setIsLoading(false)

      })
    }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(category){
      if(category === "All"){
        getAllData();
      }else{
        getFilterCategoryData(category)
      }
    }else{
      getAllData();
    }
  }, [pageNo]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(category){
      if(category === "All"){
        getAllData();
      }else{
        getFilterCategoryData(category)
      }
    }else{
      getAllData();
    }
  }, [category]);

  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    const payload = {
      _id : product.id,
      image : product?.attributes?.Image?.data?.attributes?.url || "https://res.cloudinary.com/djftqzlxr/image/upload/v1707662459/thumbnail_products1_min_441a59675d.png",
      name: product.attributes.Name,
      description: product.attributes.Categories,
      price: (product?.attributes?.Price || 0).toFixed(2),
      discount: (product?.attributes?.Discount || 0).toFixed(2),
      PST : product?.attributes?.PST,
      GST : product?.attributes?.GST,
    }
    dispatch(addToCart(payload));
    toast(`Product ${product.attributes.Name} added`)

    // navigate("/cart");
  };

  const handelSelect = (e) => {
    setUrlText(()=>parseInt(e.target.value))

    if(category){
      if(category === "All"){
        getAllData2(parseInt(e.target.value))
      }else{
        getFilterCategoryData(category)
      }
    }else{
      getAllData2(parseInt(e.target.value))  
     }
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const footerOffset = document.documentElement.offsetHeight - window.innerHeight - 200;
  
      setSticky(scrollPosition > 200 && scrollPosition < footerOffset);
    };
  
    let firstDiv = null; // Reference to the first div element
    let secondDiv = null; // Reference to the second div element
    let yourComponent = null; // Reference to YourComponent
  
    const updateWidth = () => {
      // Get the width of the first div
      if (firstDiv) {
        const width = firstDiv.offsetWidth;
        setFirstDivWidth(width);
      }
    };
  
    // Call the updateWidth function on mount
    updateWidth();
  
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
  
    // Function to toggle visibility of elements with a delay
    const toggleVisibilityWithDelay = (element) => {
      if (element) {
        setTimeout(() => {
          const currentDisplay = element.style.display;
          element.style.transition = 'none'; // Disable transition
          element.style.display = currentDisplay === 'none' ? 'block' : 'none';
          // Trigger update of width when toggling visibility
          updateWidth();
          // Re-enable transition after a short delay to avoid sudden changes
          setTimeout(() => {
            element.style.transition = ''; // Reset transition to default
          }, 50);
        }, 200); // Adjust the delay as needed
      }
    };
  
    // Function to toggle visibility of firstDiv
    const toggleFirstDiv = () => {
      toggleVisibilityWithDelay(firstDiv);
    };
  
    // Function to toggle visibility of secondDiv
    const toggleSecondDiv = () => {
      toggleVisibilityWithDelay(secondDiv);
    };
  
    // Function to toggle visibility of YourComponent
    const toggleYourComponent = () => {
      toggleVisibilityWithDelay(yourComponent);
    };
  
    // Assuming you have a button or trigger to toggle the visibility of firstDiv, secondDiv, and YourComponent
    const toggleButton = document.getElementById('toggleButton');
    if (toggleButton) {
      toggleButton.addEventListener('click', toggleFirstDiv);
    }
  
    // Assign firstDiv reference
    firstDiv = document.getElementById('firstDiv');
  
    // Assign secondDiv reference
    secondDiv = document.getElementById('secondDiv');
  
    // Assign YourComponent reference
    yourComponent = document.getElementById('yourComponent');
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      if (toggleButton) {
        toggleButton.removeEventListener('click', toggleFirstDiv);
      }
    };
  }, []);
  
  
  
  const secondDivStyle = {
  width: `${firstDivWidth}px`,
  };


  const categoryFunction = (event) => {
    setCategory(event.target.value);
    setPageNo(1)
  }

  const searchHandler = (value) => {

    if(value.target.value === ""){
      setDisable(false);
      const radioButtons = document.querySelectorAll('.form-check-input');
        if (radioButtons.length > 0) {
          radioButtons[0].checked = true;
        }
    }else{
      setDisable(true)
    }
     // Cancel the previous request
    abortController.abort();
    
    // Create a new instance of AbortController for the new request
    abortController = new AbortController();

    // Extract the signal from the abortController
    const { signal } = abortController;
    getSearchData(value.target.value, signal)
  }
  return (
    <>
    {isLoading && (
          <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(8px)', 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99999, 
          backdropFilter: 'blur(8px)',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <BounceLoader 
            loading={isLoading}
            color="#96ae00"
            size={80}

            />
          </div>
        </div>

      )}
  
      <NavBar />
      <section className="shop-area-start grey-bg pb-200 pt-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
            <div className={`your-component ${isSticky ? 'sticky' : ''}`} style={secondDivStyle} id="secondDiv">
              <div className="tpshop__leftbar" id="firstDiv">
             
                <div className="tpshop__widget mb-30 pb-25">
                  <h4 className="tpshop__widget-title">Product Categories</h4>
                  {allCategory.map((val, index) => {
                      return (
                          <div className="form-check">
                              <input
                                  className="form-check-input"
                                  type="radio"
                                  name="category"
                                  value={val?.categories}
                                  id={`flexRadioDefault${index}`}
                                  defaultChecked={category === val?.categories || (category === null && index=== 0 )}
                                  onClick={categoryFunction}
                              />
                              <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                                  {val?.categories} ({val?.count})
                              </label>
                          </div>
                      )
                  })}
                </div>
              </div>
              </div>
            </div>

            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="tpsection mb-35">
                      <h4 className="tpsection__title">{disable ? ("Filtered products") : ("All products")}</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className={`your-component ${isSticky ? 'sticky2' : ''}`} ref={filterRef}>
                    <div className="product__filter-content" ref={filterDivRef} >
                      <div className="row align-items-center">
                        <div className="col-sm-4">
                          <div className="product__item-count">
                            <span>Showing {(allData?.meta?.pagination?.pageSize * (pageNo - 1)) || 0} - {(allData?.meta?.pagination?.pageSize * (pageNo - 1)) + allData?.data?.length || 0} of {allData?.meta?.pagination?.total || 0} Products</span>
                          </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="form">
                  <i className="fa fa-search"></i>
                  <input type="text" className="form-control form-input" onChange={searchHandler} placeholder="Search anything..." />
                  <span className="left-pan"></span>
                </div>

                        </div>
                        <div className="col-sm-4">
                          <div className="product__navtabs d-flex justify-content-end align-items-center">
                            <div className="tp-shop-selector">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={handelSelect}
                                disabled={disable}
                              >
                                <option selected value="0">{disable ? ("Clear Search") : ("None")}</option>
                                <option value="1">Price - Low to High</option>
                                <option value="2">Price - High to Low</option>
                                <option value="3">Newly Added</option>
                                <option value="4">Discount</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div className="tpproduct__arrow p-relative">
                  <div className="swiper-container tpproduct-active tpslider-bottom p-relative">
                    <div className="row">

                      {/* all handlers during fetching */}
                      {allData?.data &&
                        allData?.data?.map((products) => (
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-1 mt-15">
                            <div className="tpproduct p-relative">
                            <Link to={`/product-details?id=${products.id}`}>
                              <div className="tpproduct__thumb p-relative text-center">
                                  <img
                                    src={products?.attributes?.Image?.data?.attributes?.url || "https://res.cloudinary.com/djftqzlxr/image/upload/v1730307106/thumbnail_Product_Image_Coming_Soon_removebg_preview_105e6bcca0.png?updatedAt=2024-10-30T16%3A51%3A47.251Z"}
                                    alt={products?.attributes?.Image?.data?.attributes?.url ? "Product Image" : "Image Not Found"}
                                  />
                              
                              {!!products?.attributes?.Tags && products?.attributes?.Tags !== "Undefined" && (
                                <span className="tpproduct__info bage">
                                  <span className="tpproduct__info-hot bage__hot">
                                    {products?.attributes?.Tags }
                                  </span>
                                </span>
                              ) }
                              
                              </div>
                            </Link>
                              <div className="tpproduct__content">
                                  <span className="tpproduct__content-weight text-info">
                                     {products?.attributes?.Categories || ""}
                                   
                                  </span>
                          
                                <h4 className="tpproduct__title">
                                {products?.attributes?.Name || ""}
                                </h4>

                                <div className="tpproduct__price">
                                  <span>CA ${((products?.attributes?.Price || 0) - (products?.attributes?.Discount || 0)).toFixed(2)}</span>
                                  {products?.attributes?.Discount !== 0 && (
                                  <del>CA ${(products?.attributes?.Price || 0).toFixed(2)}</del>
                                  )}
                                </div>
                              </div>
                              <div className="">
                                <div className="tpproduct__hover-btn d-flex justify-content-center mb-10">
                                
                                  <button onClick={() => handleAddToCart(products)} className="tp-btn-2">
                                    Add To Cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  <br/><br/>
                      <Stack spacing={2}>
                        <Pagination type={"next"} onChange={(event, value)=>{setPageNo(value)}} count={allData?.meta?.pagination?.pageCount || 0} disabled={false}/>
                      </Stack>
                      <br/><br/>  <br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
