import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { clearCart, getTotals } from "../slices/cartSlice";
import { Navigation, Pagination } from "swiper/modules";

import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";

import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation]);
const CheckoutSuccess = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(clearCart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <NavBar />
    <Container>
      <h2>Payment Successful</h2>
      <p>Thank you for your purchase! We have received your order and will start processing it soon.</p>
      <p> We'll notify you when your order is ready for pickup.</p>
      {/* <p>Check your order status at your profile after about 10mins or recipient email.</p> */}
      <p>
        Incase of any inquiries contact the support at{" "}
        <strong>admin@jeyabrothers.ca</strong>
      </p>

      <p> Forgot something from check list 👇</p>

      <div className="start-shopping">
            <Link to="/Shop" className="tp-btn-2 text-white">
              <span>Buy it</span>
            </Link>
          </div>
    </Container>
    <Footer />
    </>
  );
}; 

export default CheckoutSuccess;

const Container = styled.div`
  min-height: 80vh;
  max-width: 800px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 0.5rem;
    color: #029e02;
  }
`;
