import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
//import 'swiper/swiper-bundle.min.css'; // Import Swiper styles
import SwiperCore from 'swiper/core';
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import 'react-toastify/dist/ReactToastify.css';
import {  Link } from "react-router-dom";
//import { addToCart, getTotals } from "../slices/cartSlice";
import axios from "axios";
import Config from "../../../src/config/Config"


// Install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const YourComponent = () => {
  const [allCategory, setAllCategory] = useState([]);
  const imageUrls = [
    'assets/img3/1.png',
    'assets/img3/2.png',
    'assets/img3/3.png',
    'assets/img3/4.png',
    'assets/img3/5.png',
    'assets/img3/6.png',
    'assets/img3/7.png',
    'assets/img3/8.png',
    'assets/img3/9.png',
    'assets/img3/10.png',
    'assets/img3/11.png',
    'assets/img3/12.png',
    'assets/img3/13.png',
    'assets/img3/14.png',
    'assets/img3/15.png',
    'assets/img3/16.png',
    'assets/img3/17.png',
    'assets/img3/18.png',
    'assets/img3/19.png',
    'assets/img3/20.png',
    'assets/img3/21.png',
    'assets/img3/22.png',
    'assets/img3/23.png',
    'assets/img3/24.png',
    'assets/img3/25.png',
    'assets/img3/26.png',
    'assets/img3/27.png',
    'assets/img3/28.png',
    'assets/img3/29.png',
    'assets/img3/30.png',
    'assets/img3/31.png',
    'assets/img3/32.png',
    'assets/img3/33.png',
    'assets/img3/34.png',
  ];

  // Number of duplicated slides
  const slidesNum = 6;



  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchFeaturedProducts = axios.get(`${Config.BACKEND_URL}/products/sortByFeaturedProduct?page=${1}&pageSize=50`);
    const fetchProductCounts = axios.get(`${Config.BACKEND_URL}/products/counts`);

    Promise.all([fetchFeaturedProducts, fetchProductCounts]).then((results) => {
      const productCountsResult = results[1];

      if (productCountsResult.status === 200) {
        setAllCategory(productCountsResult.data);
      }
    }).catch((err) => {
      console.error("AXIOS ERROR: ", err);
    });
  }, [])

  // Duplicate slides for infinite loop
  const duplicatedSlides = [];
  for (let i = 0; i < slidesNum; i++) {
    allCategory.forEach((val, index) => {
      duplicatedSlides.push(
        <SwiperSlide key={`slide-${index}-${i}`} >
          <Link to={`/shop?category=${encodeURIComponent(val?.categories)}`}>
            <div className="swiper-slide">
              <div className="category__item mb-30">
                <div className="category__thumb fix mb-15">
                  <img
                    src={imageUrls[index]}
                    alt={val?.categories}
                    style={{ width: 40, height: 40 }}
                  />
                </div>
                <div className="category__content">
                  <h5 className="category__title">
                    <a>{val?.categories || ""}</a>
                  </h5>
                  <span className="category__count">{val?.count || ""} items</span>
                </div>
              </div>
            </div>
          </Link>
        </SwiperSlide>
      );
    });
  }

  return (
    <Swiper
      spaceBetween={30}
      //slidesPerView={6}
      navigation={true}
      pagination={{ clickable: true }}
      loop={true}
      breakpoints={{
        480: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        999: {
          slidesPerView: 6,
        },
      }}
      className="related_swiper"
  
    >
      <div className="top-50 mt-50">
        {duplicatedSlides}
      </div>
    </Swiper>
  );
};

export default YourComponent;
