import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from '../components/Navbar/NavBar';

// install Swiper modules
SwiperCore.use([Navigation]);
const Home = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
        <NavBar />

       
       
        
         <section className="about-area pt-100 pb-60">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6">
                     {/* <div className="tpabout__inner-thumb-2 p-relative mb-30"> */}
                     <div className="mb-30">
                        <img style={{"float": "right"}} src="assets/img/banner/about-inner-bg-real.webp" alt="painting"/>
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="tpabout__inner-2 mb-30">
                        <div className="tpabout__inner-tag mb-10">
                           <span className="active">About us</span>
                           <span>Welcome to Jeya Brothers</span>
                        </div>
                        
                        <p>
                           Your premier destination for <b>Sri Lankan, Indian and Fijian groceries</b> and <b>produce</b>. We specialize in authentic Sri Lankan spices, essentials, and hard-to-find ingredients, alongside a vibrant selection of fresh vegetables and fruits.
                        </p>
                        <div className="tpabout__inner-list">
                           <ul>
                              <li><i className="icon-check"></i> Bring the rich flavors and warmth straight to your kitchen.</li>
                              <li><i className="icon-check"></i> Experience the best of culinary traditions.</li>
                              <li><i className="icon-check"></i> Where tradition meets quality.</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        <br/>
        <br/>
        <br/>
         <section className="about-area pb-35">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6">
                     <div className="tpabout__item text-center mb-40">
                        
                        <div className="tpabout__content">
                           <h4 className="tpabout__title">Select Your Products</h4>
                           <p>Enjoy the ease of selecting product.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                     <div className="tpabout__item text-center mb-40">
                        
                        <div className="tpabout__content">
                           <h4 className="tpabout__title">Our Shop</h4>
                           <p>We provide 100+ products, provide <br/> enough nutrition for your family.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                     <div className="tpabout__item text-center mb-40">
                       
                        <div className="tpabout__content">
                           <h4 className="tpabout__title">Delivery To Your Door Step </h4>
                           <p>Can't come by, Enjoy our delivery to your door.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <br/>
        

      <Footer />
    </>
  );
};

export default Home;
