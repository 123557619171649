import img1 from '../../assets/img2/1.webp'
import img2 from '../../assets/img2/2.webp'
import img3 from '../../assets/img2/3.webp'
import img4 from '../../assets/img2/4.webp'
import img5 from '../../assets/img2/5.webp'

const images = [
  {
    imgURL: img1,
    imgAlt: "img-1",
  },
  {
    imgURL: img2,
    imgAlt: "img-2",
  },
  {
    imgURL:img3,
    imgAlt: "img-3",
  },
  {
    imgURL:img4,
    imgAlt: "img-4",
  },
  {
    imgURL:img5,
    imgAlt: "img-5",
  },
];

export default images;
