import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from '../components/Navbar/NavBar';
import { toast } from "react-toastify";

// install Swiper modules
SwiperCore.use([Navigation]);
const Home = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
   name: '',
   email: '',
   subject: '',
   phone: '',
   message: '',
 });

 const onSubmit = async event => {
   event.preventDefault();
   setLoading(true);
   const formData = new FormData(event.target);

   formData.append('access_key', 'c1c73732-c8e7-48d1-8813-287ed382476c');
   formData.append("subject", "Contact Us");


   const object = Object.fromEntries(formData);
   const json = JSON.stringify(object);
   const res = await fetch('https://api.web3forms.com/submit', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       Accept: 'application/json',
     },
     body: json,
   }).then(res => res.json());

   if (res.success) {
     toast("success",res.msg)
     setFormData({ name: '', email: '',phone:'', subject: '', message: '' });
     setLoading(false);
   }else{
      toast("error",res.msg)
     setLoading(false);
   }

 };

 // Handler for input field changes
 const handleInputChange = event => {
    const { name, value } = event.target;
   setFormData(prevFormData => ({
     ...prevFormData,
     [name]: value,
   }));
 };

  return (
    <>
        <NavBar />

      
        <section className="contact-area mb-45">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-lg-6">
                     <div className="tpcontact-inner text-center mt-20 mb-40">
                        <div className="tpcontact-inner-text">
                           <h5 className="tpcontact-inner-sub-title">Contact us</h5>
                           <h5 className="tpcontact-inner-title mb-20">Looking For Help</h5>
                           <p>Send us an email and we'll get back to you soon.</p>
                        </div>
                     </div>
                  </div>
               </div>
            
            </div>
         </section>
        
         <section className="map-area tpmap__box">
            <div className="container">
               <div className="row gx-0">
                  <div className="col-lg-6 col-md-6 order-2 order-md-1">
                     <div className="" style={{"display": "flex", "align-items": "center", "justify-content": "flex-end", "height": "70vh"}}>
                        <iframe width="600" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=640&amp;height=480&amp;hl=en&amp;q=3805%20Knight%20St,%20Vancouver,%20BC%20V5V%203E3,%20Canada&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 order-1 order-md-2">
                     <div className="tpform__wrapper pt-0 pb-80 ml-60">
                        <h4 className="tpform__title">Send Us a Message</h4>
                        <p>Your email address will not be published. Required fields are marked *</p>
                        <div className="tpform__box">
                           <form id="contact-form" onSubmit={onSubmit}>
                              <div className="row gx-7">
                                 <div className="col-lg-6">
                                    <div className="tpform__input mb-20">
                                       <input type="text" name="name" placeholder="Your Name *" 
                                          value={formData.name}
                                          onChange={handleInputChange}
                                          required
                                       />
                                    </div>
                                 </div>
                                 <div className="col-lg-6">
                                    <div className="tpform__input mb-20">
                                       <input type="email" placeholder="Your Email *" name="email"
                                          value={formData.email}
                                          onChange={handleInputChange}
                                          required
                                       />
                                    </div>
                                 </div>
                                 <div className="col-lg-6">
                                    <div className="tpform__input mb-20">
                                       <input type="text" placeholder="Subject *" name="subject"
                                             value={formData.subject}
                                             onChange={handleInputChange}
                                             required
                                       />
                                    </div>
                                 </div>
                                 <div className="col-lg-6">
                                    <div className="tpform__input mb-20">
                                       <input type="tel" placeholder="Phone"  name="phone"
                                             value={formData.phone}
                                             onChange={handleInputChange}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-lg-12">
                                    <div className="tpform__textarea">
                                       <textarea name="message" placeholder="Message" 
                                          value={formData.message}
                                          onChange={handleInputChange}
                                          required
                                       ></textarea>
                                        <button
                                          className={`px-btn w-100 ${loading ? 'disabled' : ''}`}
                                          type="submit"
                                          >
                                          {loading ? 'Sending...' : 'Send Message'}
                                          </button>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
     

      <Footer />
    </>
  );
};

export default Home;
