import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar/NavBar";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Config from "../../src/config/Config"
import { addToCartQuantity, getTotals } from "../slices/cartSlice";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";


// install Swiper modules
SwiperCore.use([Navigation]);
const Home = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);


  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [quantityMax, setQuantityMax] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if(id){
      axios.get(`${Config.BACKEND_URL}/products/id?productId=${id}`)
      .then((response)=>{
        const data = response?.data?.data;
        setQuantity(1)
        setQuantityMax(data.attributes.Quantity)
        setData(data)

        if(data?.attributes?.Categories){
          axios.get(Config.BACKEND_URL + `/products/filterByCategory?Categories=${encodeURIComponent(data?.attributes?.Categories)}&page=${1}&pageSize=4`)
              .then((res) => {
                const data = res.data.data;
                setCategoryData(data);
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        }
      }).catch((err)=>{
        console.log("AXIOS ERROR: ", err)
      })
    }else{
      navigate("/notfound")
    }



  }, [id]);

  // Function to handle decrement
  const handleDecrement = () => {
    setQuantity(prevQuantity => Math.max(0, prevQuantity - 1));
  };

  // Function to handle increment
  const handleIncrement = () => {
    let max;

    if(quantityMax < 10){
      max = quantityMax;
    }else{
      max = 10
    }
    setQuantity(prevQuantity => Math.min(max, prevQuantity + 1));
  };

  const handleAddToCart = () => {
    const payload = {
      _id: data?.id,
      image: data?.attributes?.Image?.data?.attributes?.url || "https://res.cloudinary.com/djftqzlxr/image/upload/v1707662459/thumbnail_products1_min_441a59675d.png",
      name: data?.attributes?.Name,
      description: data?.attributes?.Categories,
      price: (data?.attributes?.Price || 0).toFixed(2),
      discount: (data?.attributes?.Discount || 0).toFixed(2),
      PST : data?.attributes?.PST,
      GST : data?.attributes?.GST,
      quantity : quantity
    }
    setQuantity(1)
    dispatch(addToCartQuantity(payload));
    toast(`Product ${data?.attributes?.Name} added`)
};
  return (
    <>
      <NavBar />

      <section className="shopdetails-area grey-bg pb-50 ">
      <br/><br/>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className="tpdetails__area pb-30">
                <div className="tpdetails__product mb-30">
                  <div className="tpdetails__title-box">
                    <h3 className="tpdetails__title">
                      {data?.attributes?.Name}
                    </h3>
                  </div>
                  <div className="tpdetails__box">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="tpproduct-details__nab">
                          <div className="tab-content" id="nav-tabContents">
                            <div
                              className="tab-pane fade show active w-img"
                              id="nav-home"
                              role="tabpanel"
                              aria-labelledby="nav-home-tab"
                              tabindex="0"
                            >
                              <img
                                src={data?.attributes?.Image?.data?.attributes?.url ? data?.attributes?.Image?.data?.attributes?.url  : "https://res.cloudinary.com/djftqzlxr/image/upload/v1730307106/thumbnail_Product_Image_Coming_Soon_removebg_preview_105e6bcca0.png?updatedAt=2024-10-30T16%3A51%3A47.251Z"}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="product__details">
                          <div className="product__details-price-box">
                          {/* current price should be big, black color */}
                            <h5 className="product__details-price f25 blck">CA ${((data?.attributes?.Price || 0) - (data?.attributes?.Discount || 0)).toFixed(2)}</h5>
                          {/* discount amount should be small and red and strick, this will only show if there is discount */}
                            { data?.attributes?.Discount !== 0 && <h5 className="product__details-price f14"><s>CA ${(data?.attributes?.Price || 0).toFixed(2)}</s></h5>}
                          </div>
                          <div className="product__details-cart">
                            <div className="product__details-quantity d-flex align-items-center mb-15">
                              <b>Qty:</b>
                              <div className="product__details-count mr-10">
                              <span className="cart-minus" onClick={handleDecrement}>
                                <i className="fa fa-minus"></i>
                              </span>
                              <input
                                className="tp-cart-input"
                                type="text"
                                value={quantity}
                                readOnly
                              />
                              <span className="cart-plus" onClick={handleIncrement}>
                                <i className="fa fa-plus"></i>
                              </span>
                              </div>
                              <div className="product__details-btn">
                                  <button onClick={() => handleAddToCart()} className="tp-btn-2">
                                  Add To Cart
                                </button>
                                {/* <a href="cart.html">add to cart</a> */}
                              </div>
                            </div>
                          </div>
                          <div className="product__details-stock mb-25">
                            <ul>
                              <li>
                                Availability: <i>{data?.attributes?.Quantity}</i>
                              </li>
                              <li>
                                Categories:{" "}
                                <span>
                                  {data?.attributes?.Categories}{" "}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="product__details-payment text-center">
                            <center>
                              <img
                                src="assets/img/shape/footer-payment.png"
                                alt=""
                              />
                              <span>Guarantee safe & Secure checkout</span>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tpproduct__arrow p-relative">
            <div className="swiper-container tpproduct-active tpslider-bottom p-relative">
            <h4 className="tpsidebar__title mb-15">Similar Products</h4>
              <div className="row">
              {categoryData.map((value,index)=>{
                    return(
                      <div className="col-xl-3 col-lg-3 col-sm-4 mt-15">
                      <div className="tpproduct p-relative p-20 tpproduct2" >
                      <Link to={`/product-details?id=${value.id}`}>
                      <div className="tpsidebar__product-thumb p-relative">
                        <img className="p-image" src={value?.attributes?.Image?.data?.attributes?.url ? value?.attributes?.Image?.data?.attributes?.url  : "https://res.cloudinary.com/djftqzlxr/image/upload/v1730307106/thumbnail_Product_Image_Coming_Soon_removebg_preview_105e6bcca0.png?updatedAt=2024-10-30T16%3A51%3A47.251Z"} alt="" />
                      </div>
                      </Link>
                      <div className="tpsidebar__product-content">
                        <br/>
                        <span className="tpproduct__product-category">
                          <a className="tpproduct__content-weight text-info" href="shop-details-3.html">{value?.attributes?.Categories}</a>
                        </span>
                        <h4 className="tpsidebar__product-title">
                          <a href="shop-details-3.html">
                          {value?.attributes?.Name}
                          </a>
                        </h4>
                      </div>
                    </div>
                    </div>
                    )
                  })}
              </div>
            </div>
          </div>

            </div>
            <div className="col-lg-3 col-md-12">
              <div className="tpsidebar pb-30">
                <div className="tpsidebar__warning mb-30">
                  <ul>
                    <li>
                      <div className="tpsidebar__warning-item">
                        <div className="tpsidebar__warning-icon">
                          <i className="icon-package"></i>
                        </div>
                        <div className="tpsidebar__warning-text">
                          <p>
                          We offer convenient pickup options.<br /> Our delivery service is in progress <br />and will be available soon!
                            {/* Free shipping apply to all <br /> orders over $50 */}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="tpsidebar__warning-item">
                        <div className="tpsidebar__warning-icon">
                          <i className="icon-shield"></i>
                        </div>
                        <div className="tpsidebar__warning-text">
                          <p>
                            Enjoy our fast selling products<br />  from the home page
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="tpsidebar__warning-item">
                        <div className="tpsidebar__warning-icon">
                          <i className="icon-package"></i>
                        </div>
                        <div className="tpsidebar__warning-text">
                          <p>
                            Looking something <br /> Just search it on shop Page
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
