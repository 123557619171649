import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Player } from '@lordicon/react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, getTotals } from "../slices/cartSlice";
import axios from "axios";
import Config from "../../src/config/Config"
import { BounceLoader } from "react-spinners";
import Category from "../components/Catergory"

// install Swiper modules
SwiperCore.use([Navigation]);

const Home = () => {
  const { items: data, status } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);


  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    const fetchFeaturedProducts = axios.get(`${Config.BACKEND_URL}/products/sortByFeaturedProduct?page=${1}&pageSize=50`);
    const fetchProductCounts = axios.get(`${Config.BACKEND_URL}/products/counts`);

    Promise.all([fetchFeaturedProducts, fetchProductCounts]).then((results) => {
      const featuredProductsResult = results[0];
      const productCountsResult = results[1];

      if (featuredProductsResult.status === 200) {
        const data = featuredProductsResult.data?.data?.attributes?.jb_products;
        setAllData(data);
      }
      setIsLoading(false);
    }).catch((err) => {
      console.error("AXIOS ERROR: ", err);
      setIsLoading(false);
    });
  }, [])

  const handleAddToCart = (product) => {

    // console.log(product)
    const payload = {
      _id: product.id,
      image: product?.attributes?.Image?.data?.attributes?.url || "https://res.cloudinary.com/djftqzlxr/image/upload/v1707662459/thumbnail_products1_min_441a59675d.png",
      name: product.attributes.Name,
      description: product.attributes.Categories,
      price: (product.attributes.Price || 0).toFixed(2),
      discount: (product?.attributes?.Discount || 0).toFixed(2),
      PST : product?.attributes?.PST,
      GST : product?.attributes?.GST,
    }
    dispatch(addToCart(payload));
    toast(`Product ${product.attributes.Name} added`)
    // navigate("/cart");
  };


  return (
    <>
      {isLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(8px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99999,
          backdropFilter: 'blur(8px)',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <BounceLoader
              loading={isLoading}
              color="#96ae00"
              size={80}

            />
          </div>
        </div>

      )}

      <Hero />

      <section className="category-area grey-bg cat pb-40">
        <div className="container">
          <div className="swiper-container category-active">
            <div className="swiper-wrapper">
              <Category />
            </div>
          </div>
        </div>
      </section>

      <section className="product-area grey-bg ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="tpsection mb-35">
                <h4 className="tpsection__title">Best selling products</h4>
                <p>
                  Dive into the taste of freshness, our handpicked feature of the week!
                </p>
              </div>
            </div>
          </div>
          <div className="tpproduct__arrow p-relative">
            <div className="swiper-container tpproduct-active tpslider-bottom p-relative">
              <div className="row">
                {/* all handlers during fetching */}
                {allData?.data &&
                  allData?.data?.map((products) => (
                    <div className="col-xl-2 col-lg-3 col-sm-4 mt-15">
                      <div className="tpproduct p-relative">
                        <Link to={`/product-details?id=${products.id}`}>
                        <div className="tpproduct__thumb p-relative text-center">

                          <img
                            src={products?.attributes?.Image?.data?.attributes?.url || "https://res.cloudinary.com/djftqzlxr/image/upload/v1730307106/thumbnail_Product_Image_Coming_Soon_removebg_preview_105e6bcca0.png?updatedAt=2024-10-30T16%3A51%3A47.251Z"}
                            alt={products?.attributes?.Image?.data?.attributes?.url ? "Product Image" : "Image Not Found"}
                          />
                          {!!products?.attributes?.Tags && products?.attributes?.Tags !== "Undefined" && (
                            <span className="tpproduct__info bage">
                              <span className="tpproduct__info-hot bage__hot">
                                {products?.attributes?.Tags}
                              </span>
                            </span>
                          )}

                        </div>
                        </Link>
                        <div className="tpproduct__content">
                          <span className="tpproduct__content-weight text-info">
                            {products?.attributes?.Categories || ""}
                          </span>

                          <h4 className="tpproduct__title">
                            {products?.attributes?.Name || ""}
                          </h4>

                          <div className="tpproduct__price">
                            <span>CA ${((products?.attributes?.Price || 0) - (products?.attributes?.Discount || 0)).toFixed(2)}</span>
                            {products?.attributes?.Discount !== 0 && (
                              <del>CA ${(products?.attributes?.Price || 0).toFixed(2)}</del>
                            )}
                          </div>
                        </div>
                        <div className="">
                          <div className="tpproduct__hover-btn d-flex justify-content-center mb-10">

                            <button onClick={() => handleAddToCart(products)} className="tp-btn-2">
                              Add To Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-coundown-area tpcoundown__bg grey-bg grey-bg pb-25">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tpcoundown p-relative ml-175">
                <div className="section__content mb-35">
                  <span className="section__sub-title mb-10">
                    ~ Premium ~
                  </span>
                  <h2 className="section__title mb-25">
                     <br /> Fresh Farm Product
                  </h2>
                  <p>
                    We also provide farm-direct vegetables and fruits. Best deals and great offers coming your way!
                    {" "}
                    <br />
                    <q>We offer convenient pickup options. Our delivery service is in progress and will be available soon</q>

                  </p>
                </div>
                <div className="tpcoundown__count">
                  <div className="tpcoundown__btn mt-50">
                    <a className="whight-btn" href="/Shop">
                      SHOP NOW
                    </a>
                  </div>
                </div>
                <div className="tpcoundown__shape d-none d-lg-block">
                  <img
                    className="tpcoundown__shape-one"
                    src="assets/img/shape/tree-leaf-1.svg"
                    alt=""
                  />
                  <img
                    className="tpcoundown__shape-two"
                    src="assets/img/shape/tree-leaf-2.svg"
                    alt=""
                  />
                  <img
                    className="tpcoundown__shape-three"
                    src="assets/img/shape/tree-leaf-3.svg"
                    alt=""
                  />
                  <img
                    className="tpcoundown__shape-four"
                    src="assets/img/shape/fresh-shape-1.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-area pb-60 pt-60 grey-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="tpbanner__item mb-30">
                <Link to="/">
                  <div
                    className="tpbanner__content"
                    style={{
                      backgroundImage:
                        "url(" + "assets/img/banner/banner-1.jpg" + ")",
                    }}
                  >
                    <span className="tpbanner__sub-title mb-10">
                      Top offers
                    </span>
                    <h4 className="tpbanner__title mb-30">
                      Fresh
                    </h4>
                    <p>Vegetables and Fruits</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tpbanner__item mb-30">
                <Link to="/">
                  <div
                    className="tpbanner__content"
                    style={{
                      backgroundImage:
                        "url(" + "assets/img/banner/banner-2.jpg" + ")",
                    }}
                  >
                    <span className="tpbanner__sub-title tpbanner__white mb-10">
                      Weekend Deals
                    </span>
                    <h4 className="tpbanner__title mb-30">
                      Super Food <br /> Restore Your Health
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tpbanner__item mb-30">
                <Link to="/">
                  <div
                    className="tpbanner__content"
                    style={{
                      backgroundImage:
                        "url(" + "assets/img/banner/banner-3.jpg" + ")",
                    }}
                  >
                    <span className="tpbanner__sub-title mb-10">
                      Daily Made
                    </span>
                    <h4 className="tpbanner__title mb-30">
                      Snacks & Short Eats
                    </h4>
                    <p>Stocks Limited!</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
