import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Modal, Button } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logoutUser } from "../../slices/authSlice";
const NavBar = () => {

  const [top, setTop] = useState(true);
  const [isOpen, setisOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);

  function handleClick() {
    setisOpen(!isOpen);
  }

  const [isDivVisible, setDivVisibility] = useState(false);
  const handleToggle = () => {
    setDivVisibility(!isDivVisible);
  };


  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);
  const [TextHidden, setTextHidden] = useState(true);
  const onClick = () => setTextHidden(!TextHidden);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  


  return (
    <header>
     {isDivVisible &&
    <div className="mobile">
      <div className="tpsideinfo">
        <div className="tpsideinfo__search text-center pt-35">
          <span className="tpsideinfo__search-title mb-20">
            What Are You Looking For?
          </span>
          <form action="#">
            <input type="text" placeholder="Search Products..." />
            <button>
              <i className="icon-search"></i>
            </button>
          </form>
        </div>
        <nav className="mean-nav pb-20 pt-20">
          <ul>
            <li className="m-menu">
              <a href="/">Home</a>
            </li>
            <li className="m-menu">
              <a href="/shop">Shop</a>
            </li>
            {/* <li className="m-menu">
              <a href="">Promotions</a>
            </li> */}
            <li className="m-menu">
              <a href="/about">About Us</a>
            </li>
            <li className="m-menu">
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </nav>
        <div className="tpsideinfo__account-link">
          {/* <Link to="/login">
            <i className="icon-user icons"></i> Login / Register
          </Link> */}
          {/* <a href="https://jb-cms-3pyo.onrender.com/admin" target="_blank">Admin</a> */}
        </div>
      </div>
    </div>
  }

<div id="header-sticky-2" className="tpmobile-menu d-xl-none">
    
    <div className="container-fluid">
       <div className="row align-items-center">
          <div className="col-lg-4 col-md-4 col-3 col-sm-3">
             <div className="mobile-menu-icon">
                <button  onClick={handleToggle} className="tp-menu-toggle"><i className="icon-menu1"></i></button>
             </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6 col-sm-4">
             <div className="header__logo text-center">
                <a href="/"><img src="assets/img/logo/logo.png" alt="logo"/></a>
             </div>
          </div>
          <div className="col-lg-4 col-md-4 col-3 col-sm-5">
             <div className="header__info d-flex align-items-center">
                {/* <div className="header__info-search tpcolor__purple ml-10 d-none d-sm-block">
                   <button className="tp-search-toggle"><i className="icon-search"></i></button>
                </div>
                <div className="header__info-user tpcolor__yellow ml-10 d-none d-sm-block">
                   <a href="#"><i className="icon-user"></i></a>
                </div>
                <div className="header__info-wishlist tpcolor__greenish ml-10 d-none d-sm-block">
                   <a href="#"><i className="icon-heart icons"></i></a>
                </div> */}
                <div className="header__info-cart tpcolor__oasis ml-10 tp-cart-toggle">
                  <Link to="/cart">
                    <i><img src="assets/img/icon/cart-1.svg" alt=""/></i>
                      {cartTotalQuantity !== 0 && (
                        <span>{cartTotalQuantity}</span>
                        )}
                  </Link>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
      <div className="header__top theme-bg-1 d-none d-md-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="header__top-left">
                <span>
                  <i className="fa fa-phone"> &nbsp; </i><a href="tel:+1604-707-0157">+1 604-707-0157</a>
                </span>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
            <marquee style={{color:"white", "margin-left":"-70%"}}>We offer convenient pickup options. Our delivery service is in progress and will be available soon!</marquee>
              <div className="header__top-right d-flex align-items-center">
                <div className="header__top-link">
                  {auth._id ? (
                    <span>
                    <Link>
                      <span
                          onClick={() => {
                            dispatch(logoutUser(null));
                            toast("Logout successful ");
                        }}
                      >
                        Logout
                      </span>
                      {/* <a href="#">|</a>*/}
                 
                      </Link>
                     <Link to="/profile">
                    <i className="fa fa-user"></i> My Account
                     </Link>

                      </span>
                      
                ) : (<>
                    {/* <Link to="/login"><i className="icon-user icons"></i> Login / Register</Link> */}
                    {/* <a href="https://jb-cms-3pyo.onrender.com/admin" target="_blank">Admin</a> */}
                </>
                )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="header-sticky" className="header__main-area d-none d-xl-block">
        <div className="container">
          <div className="header__for-megamenu p-relative">
            <div className="row align-items-center">
              <div className="col-xl-3">
                <div className="header__logo">
                  <Link to="/">
                    <img
                      src="assets/img/logo/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="header__menu main-menu text-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li className="">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="">
                        <Link to="/shop">Shop</Link>
                      </li>

                      {/* <li className="">
                        <Link to="/">Promotions</Link>
                      </li> */}
                      <li className="">
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="">
                        <Link to="/contact">Contact Us</Link>
                      </li>

                      <li>
                        <div className="header__info d-flex align-items-center">
                          <div className="header__info-cart tpcolor__oasis ml-10 tp-cart-toggle">
                            <Link to="/cart">
                              <div>
                                <i className="fa fa-shopping-cart"></i>
                                {cartTotalQuantity !== 0 && (
                                  <span>{cartTotalQuantity}</span>
                                )}
                              </div>
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-overlay"></div>
    </header>
  );
};

export default NavBar;
