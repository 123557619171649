import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Shop from "./pages/Shop";
import Cart from "./pages/Cart";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import ProductDetails from "./pages/ProductDetails";

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./components/NotFound";
import { loadUser } from "./slices/authSlice";
import { useDispatch } from "react-redux";
import CheckoutSuccess from "./components/CheckoutSuccess";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });

    var windowOn = $(window);
    ///////////////////////////////////////////////////
    // 12. Sticky Header Js
    windowOn.on("scroll", function () {
      var scroll = windowOn.scrollTop();
      if (scroll < 170) {
        $("#header-sticky").removeClass("header-sticky");
      } else {
        $("#header-sticky").addClass("header-sticky");
      }
    });

    ///////////////////////////////////////////////////
    // 13. Sticky Header Js
    windowOn.on("scroll", function () {
      var scroll = windowOn.scrollTop();
      if (scroll < 200) {
        $("#header-sticky-2").removeClass("header-sticky");
      } else {
        $("#header-sticky-2").addClass("header-sticky");
      }
    });
  }, []);

  useDocTitle("Jeya Brothers");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);


  return (
    <>
      <Router>
        <ToastContainer 
            position="bottom-right"
            autoClose={2000}
            rtl={false}
            newestOnTop={true}
            theme="light"
        />
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/shop" element={<Shop />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route path="/checkout-success" element={<CheckoutSuccess />} />
            {/* <Route exact path="/login" element={<Login />} /> */}
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/product-details" element={<ProductDetails />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
