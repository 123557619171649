import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar/NavBar";
import { addToCart,clearCart,decreaseCart,getTotals,removeFromCart } from "../slices/cartSlice";
import { useDispatch ,useSelector} from "react-redux";
import PayButton from "./PayButton";
import { toast } from 'react-toastify';

// install Swiper modules
SwiperCore.use([Navigation]);
const Home = () => {
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    toast(`Quantity increased - ${product.name}`);

  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
    toast(`Quantity decreased - ${product.name}`);

  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
    toast(`Product removed - ${product.name}`);

  };
  const handleClearCart = () => {
    dispatch(clearCart());
    toast(`Cart emptied`);
  };

  return (
    <>
      <NavBar />

      <div className="cart-container">
      <h2>Shopping Cart</h2>
      {cart.cartItems.length === 0 ? (
        <div className="cart-empty min-he">
          <div className="alert alert-danger">Your cart is currently empty</div>
          <div className="start-shopping">
            <Link to="/Shop" className="tp-btn-2 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span>Start Shopping</span>
            </Link>
          </div>
        </div>
      ) : (
        <div>
        <div className="table-responsive">
        <table>
          <thead>
          <tr className="titles">
          <th colSpan={2}> <h3 className="product-title">Product</h3></th>
          <th>  <h3 className="price">Price</h3></th>
          <th>  <h3 className="quantity">Quantity</h3></th>
          <th>  <h3 className="total">Total</h3></th>
          {/* <th>  <h3 className="total">Tax</h3></th> */}
          </tr>
          
</thead>
          <tbody className="cart-items">
            {cart.cartItems &&
              cart.cartItems.map((cartItem) => (
                <tr className="cart-item" key={cartItem._id}>
                  <td className="cart-product">
                    <img src={cartItem.image} alt="image" />
                    <div>
                     
                    </div>
                  </td>
<td>
<h6>{cartItem.name}</h6>
                      <p>{cartItem.desc}</p>
                      <button onClick={() => handleRemoveFromCart(cartItem)}>
                        <span className="text-danger">Remove</span>
                      </button>
</td>
                  <td className="cart-product-price">CA${(cartItem.price - cartItem.discount)}</td>
                  <td className="cart-product-quantity">
                    <button onClick={() => handleDecreaseCart(cartItem)}>
                      -
                    </button>
                    <div className="count">{cartItem.cartQuantity}</div>
                    <button onClick={() => handleAddToCart(cartItem)}>+</button>
                  </td>
                  <td className="cart-product-total-price">
                    CA${((cartItem.price - cartItem.discount) * cartItem.cartQuantity).toFixed(2)}
                  </td>
                  {/* <td className="cart-product-total-price"> 
                    <i>PST:</i> <span className="norm">CA${((cartItem.price - cartItem.discount) * (cartItem.PST / 100)).toFixed(2)} </span><br/>
                    <i>GST:</i> <span className="norm">CA${((cartItem.price - cartItem.discount) * (cartItem.GST / 100)).toFixed(2)}</span>
                  </td> */}
                </tr>
              ))}
          </tbody>
          </table>
          </div>
          <div className="cart-summary">
            <button className="clear-btn btn-xs" onClick={() => handleClearCart()}>
              Clear Cart
            </button>
            <div className="cart-checkout">
              <div className="subtotal">
                <span>Total Discount</span>
                <span className="amount">CA${cart.cartTotalDiscount}</span>
              </div>
              <br/>
              <div className="subtotal">
                <span>Total</span>
                <span className="amount">CA${cart.cartTotalAmount}</span>
              </div>
              <p>*Tax included</p>
              <PayButton cartItems={cart.cartItems}  userStatus={auth._id} />

              {/* {auth._id ? (
                <PayButton cartItems={cart.cartItems} />
              ) : (
                <button
                  className="cart-login"
                  onClick={() => navigate("/login")}
                >
                  Login to Check out
                </button>
              )} */}

              <div className="continue-shopping">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  <span>Continue Shopping</span>
                </Link>
              </div>
            </div>
          </div>
      </div>
      )}
    </div>
      <Footer />
    </>
  );
};

export default Home;
