import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../config/Config";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';


const Footer = () => {
   const [ allCategory, setAllCategory ] = useState([]);
   const [email, setEmail] = useState('');


   useEffect(()=>{
      axios.get(Config.BACKEND_URL + `/products/counts`)
      .then((res) => {
        if(res?.status === 200){
          setAllCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
   },[]);

   const sendEmail = (e) => {
      e.preventDefault();

      emailjs.send(Config.EMAILJS_SERVICE_ID, Config.EMAILJS_TEMPLATE_ID, {
         'from_email': email
      }, Config.EMAILJS_USER_ID)
      .then((response) => {
         setEmail('')
         toast(`Subscription successful!`);
      }, (error) => {
         console.log('Subscription failed. Please try again.');
         toast(`Subscription failed!`);
      });
   };

  return (
    <>
      <footer>
         <div className="tpfooter__area theme-bg-2 pt-35">
            <div className="tpfooter__top pb-15">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="tpfooter__widget footer-col-1 mb-50">
                           <h4 className="tpfooter__widget-title">Let Us Help You</h4>
                           <p>If you have any question, please <br/> contact us at: 
                              <a href="mailto:admin@jeyabrothers.ca">admin@jeyabrothers.ca</a>
                           </p>
                           <div className="tpfooter__widget-social mt-45">
                              
                              <span className="tpfooter__widget-social-title mb-5">Social Media:&nbsp;&nbsp;
                              <a href="https://www.facebook.com/p/Jeya-Brothers-100066586129669/" target="_blank"><i className="fa fa-facebook-f"></i></a> &nbsp;
                              <a href="https://www.instagram.com/jeyabros/" target="_blank"><i className="fa fa-instagram"></i></a> 
                              </span>                  
                           </div>
                           <div className="tpfooter__developer mt-15">
                              <span className="tpfooter__copyright-text">Copyright © <a href="#">Jeya Brothers</a> all rights reserved. </span>  <span className="tpfooter__copyright-text">Developed and Maintained by <a href="https://orandbt.com">Orandbt</a></span>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="tpfooter__widget footer-col-2 mb-50">
                           <h4 className="tpfooter__widget-title">Looking for Help?</h4>
                           <a href="https://www.google.com/maps/dir//3805+Knight+St,+Vancouver,+BC+V5V+3E3,+Canada/@49.2505933,-123.1587418,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x5486715a2ce80e65:0xfafcb63cf7ca0f7e!2m2!1d-123.0763608!2d49.2506077?entry=ttu" target="_blank"><p>3805 Knight St, Vancouver,<br/>BC V5V 3E3, Canada</p></a>
                           <div className="tpfooter__widget-time-info mt-35">
                              <span>Monday – Sunday: <b><br/>09:00 AM – 9:00 PM</b></span>
                           </div>
                           
                           <br/>
                              <a href="https://jb-cms-3pyo.onrender.com/admin" target="_blank"><p>Admin Login</p></a>
                           
                        </div>
                     </div>
                     <div className="col-xl-2 col-lg-4 col-md-4 col-sm-5">
                        <div className="tpfooter__widget footer-col-3 mb-50">
                           <h4 className="tpfooter__widget-title">HOT CATEGORIES</h4>
                           <div className="tpfooter__widget-links">
                              <ul>
                                 {allCategory.slice(0, 5).map((val, index)=>{
                                    return(
                                       <>
                                          <Link to={`/shop?category=${encodeURIComponent(val?.categories)}`}>
                                             <li><a>{val?.categories}</a></li>
                                          </Link>
                                       </>
                                    )
                                 })}
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-8 col-sm-7">
                        <div className="tpfooter__widget footer-col-4 mb-50">
                           <h4 className="tpfooter__widget-title">Our newsletter</h4>
                           <div className="tpfooter__widget-newsletter">
                              <p>Subscribe to the mailing list to receive updates <br/> on new arrivals, discounts, and hot deals.</p>
                              <form onSubmit={sendEmail}>
                                 <span><i><img src="assets/img/shape/message-1.svg" alt=""/></i></span>
                                 <input 
                                    type="email" 
                                    placeholder="Your email address" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    required 
                                 />
                                 <button className="tpfooter__widget-newsletter-submit tp-news-btn" type="submit">
                                    Subscribe
                                 </button>
                              </form>
                              {/* <div className="tpfooter__widget-newsletter-check mt-10">
                                 <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label className="form-check-label" for="flexCheckDefault">
                                       I accept terms & conditions & privacy policy.
                                    </label>
                                  </div>                                  
                              </div> */}
                              <div className="tpfooter__copyright-thumb text-end">
                                 <br/>
                           <img src="assets/img/shape/footer-payment.png " alt=""/>
                        </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          
         </div>
      
      </footer>
    </>
  );
};
export default Footer;
