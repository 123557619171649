import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import NavBar from '../components/Navbar/NavBar';
import axios from "axios";
import jwtDecode from 'jwt-decode';
import Config from "../config/Config";
import { toast } from "react-toastify";


// install Swiper modules
SwiperCore.use([Navigation]);

const Home = () => {

  const accessToken = localStorage.getItem("token");
  // Decode the token
  const decodedToken = jwtDecode(accessToken);

  const [profile, setProfile] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    gender: "",
    dob: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    gender: "",
    dob: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    email: "",
    phone: "",
    name: ""
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    // Canadian phone number format: XXX-XXX-XXXX or (XXX) XXX-XXXX
    const phoneRegex = /^(\(\d{3}\) |\d{3})\d{3}\d{4}$/;
    return phoneRegex.test(phone);
  };



  useEffect(()=>{
    window.scrollTo(0, 0);
    axios.get(Config.BACKEND_URL + `/user/${decodedToken?._id}`,{
      headers: {
        'x-auth-token': accessToken
      }
    })
      .then((res) => {
        if(res?.status === 200){
          const payload = {
            fullName: res?.data?.name || "",
            email: res?.data?.email || "",
            phone: res?.data?.phone || "",
            address: res?.data?.address || "",
            gender: res?.data?.gender || "",
            dob: res?.data?.dob || "",
          }

          setEditedProfile(payload);
          setProfile(payload)
          
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  },[]);

  const setUserData = (data) => {
    const payload = {
      name: data.fullName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      gender: data.gender,
      dob: data.dob,
    }
    axios.put(Config.BACKEND_URL + `/user/${decodedToken?._id}`,payload,{
      headers: {
        'x-auth-token': accessToken
      }
    },
    
    )
      .then((res) => {
        setProfile(editedProfile);
        setEditMode(false);
        toast("User updated");
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleResetClick = () => {
    setEditedProfile(profile);
    setValidationErrors({ email: "", phone: "", name: "" });

  };

  const cancelClick = () => {
    setEditedProfile(profile);
    setValidationErrors({ email: "", phone: "", name: "" });

    setEditMode(false);
  }

  const handleSaveClick = () => {
    setValidationErrors({ email: "", phone: "", name: "" });

    if (editedProfile.fullName === "") {
      setValidationErrors((prev) => ({ ...prev, name: "Cannot be empty" }));
      return; // Stop the function if there's an error
    }
    // Validate email and phone
    if (!isValidEmail(editedProfile.email)) {
      setValidationErrors((prev) => ({ ...prev, email: "Invalid email format" }));
      return; // Stop the function if there's an error
    }
    if(editedProfile.phone !== ""){
      if (!isValidPhone(editedProfile.phone)) {
        setValidationErrors((prev) => ({ ...prev, phone: "Invalid phone number format. Use XXX-XXX-XXXX" }));
        return; // Stop the function if there's an error
      }
    }
    setUserData(editedProfile)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prevState => ({ ...prevState, [name]: value }));
  };


  return (
    <>
        <NavBar />

          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <div className="profile-header">
                        
                        <div className="mt-50">
                          
                          <div className="profile-name">
                            <h3 className="name"><strong>{decodedToken.name}</strong></h3>
                            <p className="email">{decodedToken.email}</p>
                          </div>
                        </div>
                      </div>
              </div>
            </div>
        <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="card tpchoose__item text-center mb-30">
                       
                        <div className="card-body tpchoose__content">
                          <h1 className="text-success">50</h1>
                           <h4 className="tpchoose__title">Orders</h4>
                          
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="card tpchoose__item text-center mb-30">
                       
                        <div className="card-body tpchoose__content">
                          <h1 className="text-success">50</h1>
                           <h4 className="tpchoose__title">Orders</h4>
                          
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                     <div className="card tpchoose__item text-center mb-30">
                       
                        <div className="card-body tpchoose__content">
                          <h1 className="text-success">~ Nill ~</h1>
                           <h4 className="tpchoose__title">Coupon</h4>
                          
                        </div>
                     </div>
                  </div>
               </div>
               </div>
        <div className="row justify-content-center"> 
          <div className="col-lg-9">
            <div className="profile">
            
            <div className="profile-body">
                <div className="profile-title">
                  <h5 className="title">Personal Details
                  {!editMode ? (
                    <>
                    <button onClick={handleEditClick} className="btn btn-primary mt-5" style={{"float": "right"}}>Edit</button>
                    </>
                      ): (
                      <div className="edit-buttons" style={{"float": "right"}}>
                        <button onClick={handleResetClick} className="btn btn-secondary">Reset</button>
                        <button onClick={handleSaveClick} className="btn btn-success">Save</button>
                        <button onClick={cancelClick} className="btn btn-warning">Cancel</button>
                      </div>
                    )}
                  </h5>
                </div>
                <div className="profile-details">
  {Object.entries(profile).map(([key, value]) => (
    <div className="single-details-item d-flex flex-wrap" key={key}>
      <div className="details-title">
        <h6 className="title">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</h6>
      </div>
      <div className="details-content media-body">
        {editMode ? (
          <>
            {key === 'dob' ? (
              <input
                type="date"
                name={key}
                value={editedProfile[key]}
                onChange={handleChange}
                className="form-control"
              />
              
            ) : key === 'email' ? (
              <>
              <input
                type="email"
                name={key}
                value={editedProfile[key]}
                onChange={handleChange}
                className="form-control"
              />
              {validationErrors.email && <p style={{ color: "red" }}>{validationErrors.email}</p>}
              </>
            ) : key === 'phone' ? (
              <>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">+1</span>
                </div>
                <input
                  type="tel"
                  name={key}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  value={editedProfile[key]}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="XXX-XXX-XXXX"
                />
              </div>
              {validationErrors.phone && <p style={{ color: "red" }}>{validationErrors.phone}</p>}
              </>
            ) : key === 'gender' ? (
              <select
                name={key}
                value={editedProfile[key]}
                onChange={handleChange}
                className="form-control">
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
              </select>
            ) : (
              <input
                type="text"
                name={key}
                value={editedProfile[key]}
                onChange={handleChange}
                className="form-control"
              />
              )}
              </>
              ) : (
              <p>{value}</p>
              )}
              </div>
              </div>
              ))}
              </div>


                
              </div>
            
            </div>
          </div>
        </div>
      <br/> <br/> <br/>

      <Footer />
    </>
  );
};

export default Home;
