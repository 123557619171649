import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import CustomSlider from "../components/Slider/Scripts";
import images from "../components/Slider/Images";
const Hero = () => {
  return (
    <>
      <div className="hero" id="hero">
        <div>
          <NavBar />
        </div>

        <section className="slider-area tpslider-delay">
          <CustomSlider>
            {images.map((image, index) => {
              {/* auto=compress&cs=tinysrgb&h=550&w=1920 */}
              return <img key={index} src={image.imgURL} alt={image.imgAlt} height={550} width={"100%"}/>;
            })}
          </CustomSlider>
        </section>
      </div>
    </>
  );
};

export default Hero;
